import { useLanguage } from "../../../stores/LanguageStore";
import {
  ActionIcon,
  Anchor,
  CopyButton,
  Divider,
  Group,
  Indicator,
  Paper,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconCopy,
  IconEdit,
  IconExternalLink,
  IconFile,
  IconFileTypeCsv,
  IconFileTypeDoc,
  IconFileTypeHtml,
  IconFileTypePdf,
  IconFileTypePpt,
  IconShare,
} from "@tabler/icons-react";
import ItemDeleter from "../../../components/TableComponents/ItemDeleter";
import { deleteFile, editFile } from "../../../api/FIleAPI";
import { useUIStore } from "../../../stores/UIStore";
import { Link } from "react-router-dom";
import AddFile from "./AddFile";
import { useState } from "react";
import { SharingEditor } from "../../../layouts/Table/columns/sharingColumn";
import PopOver from "../../../components/View/PopOver";

const icon_props = { size: 48 };
function onDragStart(ev, id, hasFolder) {
  if (hasFolder) useUIStore.getState().setDragging(true);
  ev.dataTransfer.setData("text/plain", id);
}
function onDragStop(ev, id) {
  useUIStore.getState().setDragging(false);
}

const FilesItem = ({ item, handleInsertContent, setRows, inModal }) => {
  const lang = useLanguage((s) => s.language);
  const [openedAdder, setOpenedAdder] = useState(false);

  const getLogo = () => {
    const ext = item.file_url;
    if (ext.endsWith(".pdf")) {
      return <IconFileTypePdf {...icon_props} />;
    }
    if (item.type === "excel") {
      return <IconFileTypeCsv {...icon_props} />;
    }
    if (item.type === "powerpoint") {
      return <IconFileTypePpt {...icon_props} />;
    }
    if (item.type === "docs") {
      return <IconFileTypeDoc {...icon_props} />;
    }
    if (ext.endsWith(".html")) {
      return <IconFileTypeHtml {...icon_props} />;
    }
    return <IconFile {...icon_props} />;
  };

  const onAdd = ({ isImage, width, height, label, toNew }) => {
    if (isImage) {
      return handleInsertContent(
        `<img src="${item.file_url}" alt="${item.name}" width="${width}px" height="${height}px" />`
      );
    }
    return handleInsertContent(
      `<a href="${item.file_url}" target="${
        toNew ? "_blank" : "_self"
      }" >${label}</a>`
    );
  };

  const handleSharingEdit = (newSharing) => {
    setRows((prev) => {
      return prev.map((x) => {
        if (x.id === item.id) {
          return { ...x, sharing: newSharing };
        }
        return x;
      });
    });
  };

  return (
    <Paper
      component={Stack}
      // shadow="sm"
      withBorder
      gap={"xs"}
      p={"sm"}
      pb={4}
      radius={"md"}
      h={185}
    >
      <Stack pos={"relative"} h={"100%"}>
        <Text
          ta={"center"}
          fw={500}
          truncate="end"
          draggable={true}
          onDragStart={(e) =>
            onDragStart(e, JSON.stringify(item), !!item?.folder)
          }
          onDragStop={onDragStop}
          onDragEnd={onDragStop}
          style={{ cursor: "grab" }}
        >
          {item.name}
        </Text>
        {item.type === "image" ? (
          <Stack
            h={64}
            px={4}
            mb="auto"
            style={{ cursor: "pointer" }}
            onClick={() => setOpenedAdder(true)}
          >
            <img
              src={item.thumbnail}
              alt={item.name}
              style={{
                objectFit: "contain",
                height: "100%",
              }}
            />
          </Stack>
        ) : (
          <Stack
            flex={"1 1 auto"}
            justify="center"
            w={"100%"}
            px={4}
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => setOpenedAdder(true)}
          >
            {getLogo()}
          </Stack>
        )}
      </Stack>
      <Divider />
      <Group justify="space-between" wrap="nowrap" gap={4}>
        <Tooltip label={lang.files.grid.visualize}>
          <Anchor href={item.file_url} target="_blank">
            <ActionIcon variant="subtle" size={"md"}>
              <IconExternalLink size={18} />
            </ActionIcon>
          </Anchor>
        </Tooltip>

        <CopyButton value={item.file_url}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? lang.files.var_copied : lang.files.copy_variable}
              color={copied ? "green" : "black"}
            >
              <ActionIcon variant="subtle" size={"md"} onClick={copy}>
                <IconCopy size={18} />
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>

        {inModal ? (
          <AddFile
            onAdd={onAdd}
            item={item}
            isOpened={openedAdder}
            setIsOpened={setOpenedAdder}
          />
        ) : (
          <ActionIcon
            component={Link}
            to={item.id + "/edit"}
            variant="subtle"
            size={"md"}
            color="tertiary.8"
          >
            <IconEdit size={18} />
          </ActionIcon>
        )}

        <ItemDeleter
          title_bold={`${item.name} #${item.id}`}
          handleDelete={deleteFile}
          deletedMessage={lang.files.delete_success}
          onFinish={() =>
            setRows((prev) => prev.filter((x) => x.id !== item.id))
          }
          disabled={
            !item?.delete_permission && !item?.permission?.delete_permission
          }
          id={item.id}
        />

        <PopOver
          opener={(openFunction, ref, opened) => (
            <Indicator
              label={item.sharing.length}
              color="red"
              size={14}
              disabled={item.sharing.length === 0}
            >
              <ActionIcon
                variant="subtle"
                size={"md"}
                color="secondary"
                onClick={openFunction}
                ref={ref}
                disabled={
                  !item?.write_permission && !item?.permission?.write_permission
                }
              >
                <IconShare size={16} />
              </ActionIcon>
            </Indicator>
          )}
          popoverChildren={(closePopover) => (
            <SharingEditor
              editFunction={editFile}
              field={"sharing"}
              row={item}
              closePopover={closePopover}
              onFinish={handleSharingEdit}
            />
          )}
          elevation={3}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        />
      </Group>
    </Paper>
  );
};

export default FilesItem;
