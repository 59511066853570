import { Button, Popover, Stack } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { useState } from "react";
import { downloadEML } from "../../../../../api/PRMAPI";
import { useLanguage } from "../../../../../stores/LanguageStore";

const AnswerDownloader = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(null);

  const handleDownloadHTML = () => {
    setLoading("html");
    const blob = new Blob([item?.reply || item?.message], {
      type: "text/html",
    });
    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute of the link to the Blob
    link.href = window.URL.createObjectURL(blob);

    // Set the download attribute and file name
    link.download = "Answer.html";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
    setLoading(null);
  };
  const handleDownloadEML = () => {
    setLoading("eml");
    downloadEML({ reply_id: item.id }).finally(() => setLoading(null));
  };

  return (
    <Popover opened={opened} onChange={setOpened} withinPortal={false}>
      <Popover.Target>
        <Button
          variant="light"
          onClick={() => setOpened(true)}
          leftSection={<IconDownload size={18} />}
          size="compact-sm"
          ml={"auto"}
        >
          {lang.files.download}
        </Button>
      </Popover.Target>

      <Popover.Dropdown p={"xs"}>
        <Stack gap={0}>
          <Button
            onClick={handleDownloadHTML}
            loading={loading === "html"}
            variant="subtle"
          >
            .HTML
          </Button>
          <Button
            onClick={handleDownloadEML}
            loading={loading === "eml"}
            variant="subtle"
          >
            .EML
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default AnswerDownloader;
