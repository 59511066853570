import { ActionIcon, Flex, Group, Stack, Text, Tooltip } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import {
  deleteContact,
  deleteFilterContacts,
  downloadFilterList,
  editContact,
  findList,
  getFirstPageContacts,
  searchInContactlist,
} from "../../../../api/ContactAPI";
import MainTable from "../../../../layouts/Table/MainTable";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import ListDetails from "./ListDetails";
import TableFilterDelete from "../../../../components/TableComponents/TableFilterDelete";
import TableFilterExport from "../../../../components/TableComponents/TableFilterExport";
import TableFilterCopyContacts from "../components/copy/TableFilterCopyContacts";
import { useContactHandler } from "../components/useContactHandler";
import SearchListsButton from "../components/SearchListsButton";
import {
  IconLanguage,
  IconMailSearch,
  IconMessage2Bolt,
} from "@tabler/icons-react";
import ListActions from "../actions/ListActions";
import { getChangedKeys } from "../listUtils";
import { notifications } from "@mantine/notifications";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import CreateListMenu from "../components/CreateListMenu";
import CreateField from "../components/create-field/CreateField";
import PageTitle from "../../../../components/View/PageTitle";
import { useRouteLoaderData } from "react-router-dom";
import GenerateDatafieldContent from "../actions/GenerateDatafieldContent";
import EnrichDatafield from "../actions/EnrichDatafield";
import kaspr_logo from "../../../../assets/logos/kaspr.png";
import EnrichDatafieldExternal from "../actions/EnrichDatafieldExternal";
import { useProfileStore } from "../../../../stores/UserStore";

const ContactsTableView = ({ inFolder }) => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const listProfile = useRouteLoaderData(
    inFolder ? "folder-contact-list" : "contact-list"
  );
  const [currentList, setCurrentList] = useState(listProfile);
  const [loadProfile, setLoadProfile] = useState(false);
  const [reloadColumns, setReloadColumns] = useState(true);
  const listId = currentList.id;

  const customFieldHeader = useCallback(
    (datafield) => (
      <Group wrap="nowrap" gap={4}>
        <GenerateDatafieldContent
          current={datafield}
          onFinish={() => setLoadProfile(true)}
        />
        <Text>{datafield?.name}</Text>{" "}
      </Group>
    ),
    []
  );

  const {
    loading,
    columns,
    rows,
    rowCount,
    serverInfo,
    handleLoading,
    onFilterChange,
    onPaginationChange,
    onSortChange,
  } = useContactHandler({
    apiRef,
    listId,
    getFirstPage: getFirstPageContacts,
    searchFunction: searchInContactlist,
    additionalMainRequest: () => setLoadProfile(true),
    renderHeader: {
      2: ({ datafield }) => {
        return (
          <Group wrap="nowrap" gap={4}>
            <Tooltip label={lang.contact_lists.translate.label}>
              <ActionIcon
                variant="subtle"
                color="tertiary.8"
                onClick={(e) => {
                  document.getElementById("list-translate-button")?.click();
                  e.stopPropagation();
                }}
              >
                <IconLanguage size={17} />
              </ActionIcon>
            </Tooltip>
            <Text>{datafield?.name}</Text>
          </Group>
        );
      },
      1: ({ datafield }) => {
        return (
          useProfileStore.getState().profile?.level === "admin" ?
            <Group wrap="nowrap" gap={4}>
              <EnrichDatafield
                current={datafield}
                onFinish={() => setLoadProfile(true)}
              />
              <Text>{datafield?.name}</Text>{" "}
            </Group>
            :
            <Group wrap="nowrap" gap={4}>
              <Tooltip label={lang.contact_lists.email_verifier.label}>
                <ActionIcon
                  variant="subtle"
                  color="tertiary.8"
                  onClick={(e) => {
                    document.getElementById("email-verifier-button")?.click();
                    e.stopPropagation();
                  }}
                >
                  <IconMailSearch size={17} />
                </ActionIcon>
              </Tooltip>
              <Text>{datafield?.name}</Text>
            </Group>
        );
      },
      6: ({ datafield }) => {
        return (
          useProfileStore.getState().profile?.level === "admin" ?
            <Group wrap="nowrap" gap={4}>
              <EnrichDatafield
                current={datafield}
                onFinish={() => setLoadProfile(true)}
              />
              <Text>{datafield?.name}</Text>{" "}
            </Group>
            :
            <Group wrap="nowrap" gap={4}>
              <Text>{datafield?.name}</Text>
            </Group>
        );
      },
      29: ({ datafield }) => {
        return (
          <Group wrap="nowrap" gap={4}>
            <Tooltip label={lang.contact_lists.icebreaker.label}>
              <ActionIcon
                variant="subtle"
                color="tertiary.8"
                onClick={(e) => {
                  document.getElementById("icebreaker-button")?.click();
                  e.stopPropagation();
                }}
              >
                <IconMessage2Bolt size={17} />
              </ActionIcon>
            </Tooltip>
            <Text>{datafield?.name}</Text>
          </Group>
        );
      },
      13: ({ datafield }) => {
        return (
          <Group wrap="nowrap" gap={4}>
            <EnrichDatafield
              current={datafield}
              onFinish={() => setLoadProfile(true)}
            />
            <Text>{datafield?.name}</Text>
          </Group>
        );
      },
      7: ({ datafield }) => {
        return (
          <Group wrap="nowrap" gap={4}>
            <Tooltip label={lang.contact_lists.enrich_contacts.tooltip_kaspr}>
              <ActionIcon
                variant="subtle"
                onClick={(e) => {
                  document.getElementById("kaspr-button")?.click();
                  e.stopPropagation();
                }}
              >
                <img src={kaspr_logo} style={{ width: 20 }} />
              </ActionIcon>
            </Tooltip>
            <Text>{datafield?.name}</Text>
          </Group>
        );
      },
      18: ({ datafield }) => {
        return (
          <Group wrap="nowrap" gap={4}>
            <EnrichDatafield
              current={datafield}
              onFinish={() => setLoadProfile(true)}
            />
            <Text>{datafield?.name}</Text>{" "}
          </Group>
        );
      },
      19: ({ datafield }) => {
        return (
          <Group wrap="nowrap" gap={4}>
            <EnrichDatafield
              current={datafield}
              onFinish={() => setLoadProfile(true)}
            />
            <Text>{datafield?.name}</Text>{" "}
          </Group>
        );
      },
    },
    headerRendererCustomField: customFieldHeader,
    cellEditable: true,
    disableEdit: true,
    pageOrderName: "contact_list_fetch_contacts_order",
    reloadColumns,
    setReloadColumns
  });

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <ItemDeleter
          title_bold={`${row[4]} ${row[3]}`}
          handleDelete={deleteContact}
          deletedMessage={lang.contact_lists.delete_success}
          parentId={listId}
          id={row.id}
          apiRef={apiRef}
        />
      );
    },
    [apiRef, lang.contact_lists.delete_success, listId]
  );

  const toolbarActions = useCallback(
    ({ selectedIds, filter }) => (
      <>
        <SearchListsButton />
        <TableFilterDelete
          id={listId}
          selectedIds={selectedIds}
          filter={filter}
          handleDelete={deleteFilterContacts}
          apiRef={apiRef}
          onFinish={(type) => handleLoading(type !== "selection")}
          filterResultNumber={rowCount}
          total={currentList?.number_of_contacts}
        />
        <TableFilterExport
          id={listId}
          selectedIds={selectedIds}
          filter={filter}
          handleExport={downloadFilterList}
          name={currentList?.name}
          filterResultNumber={rowCount}
          total={currentList?.number_of_contacts}
        />
        <TableFilterCopyContacts
          id={listId}
          selectedIds={selectedIds}
          filter={filter}
          name={currentList?.name}
          filterResultNumber={rowCount}
          total={currentList?.number_of_contacts}
          onFinish={() => setLoadProfile(true)}
        />
        <EnrichDatafieldExternal
          filter={filter}
          filterResultNumber={rowCount}
          total={currentList?.number_of_contacts}
          onFinish={() => setLoadProfile(true)}
        />

        <ListActions
          current={currentList}
          inside
          onFinish={() => setLoadProfile(true)}
        />
      </>
    ),
    [apiRef, currentList, handleLoading, listId, rowCount, setLoadProfile]
  );

  useEffect(() => {
    if (loadProfile) {
      findList(listId)
        .then((response) => {
          setCurrentList(response.data.contact_list_profile);
        })
        .finally(() => setLoadProfile(false));
    }
  }, [listId, loadProfile]);

  const handleRowUpdate = useCallback(async (updatedRow, originalRow) => {
    const changedKey = getChangedKeys(updatedRow, originalRow);
    if (updatedRow[changedKey] !== originalRow[changedKey]) {
      let cellElement = document.querySelector(
        `[data-id="${originalRow.id}"] > [data-field="${changedKey}"]`
      );
      cellElement.style.opacity = 0.3;
      editContact({
        contact_id: originalRow?.id,
        list_id: originalRow?.contact_list_id,
        properties: [
          {
            data_field_id: parseInt(changedKey),
            value: updatedRow[changedKey],
          },
        ],
      })
        .finally((res) => {
          cellElement.style.opacity = 1;
        })
        .catch((err) => {
          cellElement.querySelector("p").innerHTML =
            originalRow?.[changedKey] || "-";
        });

      return updatedRow;
    }

    return originalRow;
  }, []);

  const handleRowUpdateError = useCallback((err) => {
    return notifications.show({ message: "Contact non modifié", color: "red" });
  }, []);

  const handleNewField = useCallback(({ data_field_id }) => {
    setReloadColumns(true);
  }, []);

  if (!columns || !listProfile) {
    return;
  }

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle
          title={listProfile?.name || lang.contact_lists.browser_tab_title}
        />
        <CreateField onFinish={handleNewField} />
        <CreateListMenu />
      </Group>

      <TableLayoutBlock>
        <Stack flex={"1 1 auto"} w={0} miw={"100%"} gap={0}>
          <ListDetails
            serverInfo={serverInfo}
            currentList={currentList}
            setCurrentList={setCurrentList}
            handleLoading={handleLoading}
            handleGetList={() => setLoadProfile(true)}
          />
          <Flex flex={"1 1 auto"} h={0}>
            <MainTable
              isClient={false}
              apiRef={apiRef}
              columns={columns}
              rows={rows}
              rowCount={rowCount}
              loading={loading}
              setLoading={() => {
                handleLoading();
              }}
              id={"list-table"}
              hasFolder
              pageName={"contact_lists_contacts"}
              onPaginationChange={onPaginationChange}
              actionsCell={actionsCell}
              onFilterChange={onFilterChange}
              onSortChange={onSortChange}
              pageOrderName="contact_list_fetch_contacts_order"
              toolbarActions={toolbarActions}
              actionsCellWidth={50}
              withBorderTop
              handleRowUpdate={handleRowUpdate}
              handleRowUpdateError={handleRowUpdateError}
              additionalFilter={[{
                field: "bounced",
                headerName: lang.contact_lists.bounced,
                type: "exist",
                filterable: true
              }]}
            />
          </Flex>
        </Stack>
      </TableLayoutBlock>
    </>
  );
};

export default ContactsTableView;
