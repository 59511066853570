import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import '@mantine/spotlight/styles.css';
import "./App.css";
import { Center, MantineProvider } from "@mantine/core";
import { Routes } from "./Routes";
import { Notifications } from "@mantine/notifications";
import { useThemeStore } from "./stores/ThemeStore";
import { useEffect, useMemo, useState } from "react";
import { getApiDetailsDomain } from "./api/ResellerAPI";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
LicenseInfo.setLicenseKey(
  "80f80ed9a991b91afefc09f19022f0daTz03ODI4NCxFPTE3MzEwNzg2MTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ModalsProvider } from "@mantine/modals";
import FallbackPage from "./pages/unprotected/errors/FallbackPage";
import CookieHandler from "./CookieHandler";
import { useUIStore } from "./stores/UIStore";
import { useLanguage } from "./stores/LanguageStore";
dayjs.extend(customParseFormat);

function App() {
  const theme = useThemeStore((s) => s.theme);
  const isMaintenance = useMemo(() => {
    return location.href.includes("maintenance");
  }, []);

  //#region Reseller
  const [loading, setLoading] = useState(isMaintenance ? false : true);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let lang = params.get("lang");

    if (lang) {
      useLanguage.getState().changeLanguage(lang);
    }

    if (!isMaintenance) {
      getApiDetailsDomain().then((response) => {
        let reseller = response.data.api;
        const setTheme = useThemeStore.getState().setTheme;

        setTheme(
          reseller.name === "Magileads" ? null : "#" + reseller?.color_primary,
          reseller.name === "Magileads" ? null : "#" + reseller?.color_secondary
        );
        // const defaultPrimaryColor = "#2b0a2a";
        // const defaultSecondaryColor = "#055ea3";
        // setTheme(defaultPrimaryColor, defaultSecondaryColor);

        // favicon
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
        }
        link.href = reseller?.favicon_url || "favicons/favicon.ico";
        document.title = reseller?.name;

        setLoading(false);
        useUIStore.getState().setWindowLoading(true);
      });
    }
  }, []);

  //#endregion

  return (
    <MantineProvider theme={theme}>
      <ModalsProvider modalProps={{ keepMounted: false }}>
        <Notifications
          zIndex={10000}
          w={"auto"}
          styles={{
            notification: {
              boxShadow: "#00000021 0 2px 4px",
              marginRight: 54,
              border: "1px solid #00000021",
            },
          }}
        />
        <CookieHandler />
        {loading ? (
          <Center w={"100vw"} h={"100vh"}>
            <FallbackPage loading />
          </Center>
        ) : (
          <Routes />
        )}
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
