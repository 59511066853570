import {
  ActionIcon,
  Anchor,
  Group,
  HoverCard,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { requestPage } from "../../../../api/ContactAPI";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { useCallback, useEffect, useState } from "react";
import { defaultPageSize } from "../../../../layouts/Table/initialState";
import {
  transformFilterToServer,
  transformSortToServer,
} from "../../../../functions/utilFunctions";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import { IconBrandLinkedin, IconCheck } from "@tabler/icons-react";
import { useProfileStore } from "../../../../stores/UserStore";

function getDatafieldColumnWidth(datafield) {
  if (datafield.identifier_placeholder === "%ice_breaker%") {
    return 200;
  }
  return 160;
}

function handleContacts(results, resultsHandler) {
  if (resultsHandler) {
    return resultsHandler(results);
  }

  const datafields = useConfigStore.getState().datafields;
  let newContacts = [];
  results.forEach((contact) => {
    let result = {
      id: contact.id,
      contact_list_id: contact.contact_list_id,
      ...contact,
    };
    contact.properties.forEach((prop) => {
      let field = datafields.find((x) => x.id === prop.data_field_id);

      if (field) result[field.id] = prop.value;
    });

    delete contact.properties;

    if (result.id) newContacts.push(result);
  });

  return newContacts;
}

function getPreCell(datafield, row) {
  let emailStatus = row["23"];
  if (datafield?.identifier_placeholder === "%last_name%" && row["20"]) {
    return (
      <ActionIcon
        component={Anchor}
        href={row["20"]}
        target="_blank"
        c={"blue"}
        size={"xs"}
        color="blue"
        variant="subtle"
        h={"100%"}
      >
        <IconBrandLinkedin size={20} style={{ marginTop: 2 }} />
      </ActionIcon>
    );
  }
  if (
    datafield?.identifier_placeholder === "%email%" &&
    row["1"] &&
    emailStatus
  ) {
    return (
      <Tooltip
        label={
          emailStatus === "valid"
            ? "Found & deliverable"
            : "Found and probably deliverable"
        }
        openDelay={200}
      >
        <ThemeIcon
          size={16}
          color={"tertiary.8"}
          variant={emailStatus === "valid" ? "filled" : "light"}
          radius={"sm"}
          h={"100%"}
        >
          <IconCheck size={14} />
        </ThemeIcon>
      </Tooltip>
    );
  }

  return "";
}

function wrapLinksInAnchor(text) {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s,.;:\s]+(?:\.[^\s,.;:\s]+)+)/g;

  // Split text by URLs
  const parts = text.split(urlRegex);

  // Iterate through parts and wrap URLs in Anchor component
  return parts.map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <Anchor key={index} href={part} target="_blank" fw={600}>
          {part}
        </Anchor>
      );
    } else {
      return part;
    }
  });
}

function Cell({ row, value, datafield }) {
  return (
    <Group wrap="nowrap" gap={6} w={"100%"}>
      {getPreCell(datafield, row)}
      {value?.length > 20 ? (
        <HoverCard position="top" withArrow offset={2} openDelay={150}>
          <HoverCard.Target>
            {value?.includes("https://") ? (
              <Text truncate="end" fw={500} fz={13} w={"fit-content"}>
                {wrapLinksInAnchor(value)}
              </Text>
            ) : (
              <Text truncate="end" fw={500} fz={13} w={"fit-content"}>
                {value || "-"}
              </Text>
            )}
          </HoverCard.Target>
          <HoverCard.Dropdown maw={300} style={{ overflow: "hidden" }}>
            <Stack w={"100%"}>
              {value?.includes("https://") ? (
                <Text fw={500} fz={13} truncate="end">
                  {wrapLinksInAnchor(value)}
                </Text>
              ) : (
                <Text fw={500} fz={13}>
                  {value || "-"}
                </Text>
              )}
            </Stack>
          </HoverCard.Dropdown>
        </HoverCard>
      ) : (
        <Tooltip
          label={value}
          openDelay={500}
          multiline
          maw={300}
          withArrow
          disabled={!value}
        >
          {value?.includes("https://") ? (
            <Text truncate="end" fw={500} fz={13} w={"fit-content"}>
              {wrapLinksInAnchor(value)}
            </Text>
          ) : (
            <Text truncate="end" fw={500} fz={13} w={"fit-content"}>
              {value || "-"}
            </Text>
          )}
        </Tooltip>
      )}
    </Group>
  );
}

export const useContactHandler = ({
  apiRef,
  searchFunction,
  getFirstPage,
  additionalMainRequest,
  listId,
  disableEdit = false,
  startingColumns = [],
  additionalColumns = [],
  renderHeader,
  headerRendererCustomField,
  columnsHandler,
  resultsHandler,
  disableQueryTransform,
  idField = "id",
  cellEditable,
  pageOrderName,
  reloadColumns = true,
  setReloadColumns,
}) => {
  const [columns, setColumns] = useState(null);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [serverInfo, setServerInfo] = useState({
    number_of_pages: 0,
    number_of_results: 0,
    url: "",
  });

  const handleLoading = useCallback(
    (isReset, customGetter) => {
      if (loading) {
        return setLoading(false);
      }
      const pagination = apiRef.current.state.pagination.paginationModel;
      const filterModel = apiRef.current.state.filter.filterModel;
      const sortModel = apiRef.current.state.sorting.sortModel;
      const query = filterModel.quickFilterValues?.join(" ");

      setLoading(true);
      if (pagination.page === 0 || isReset) {
        const main = query
          ? searchFunction
          : customGetter
          ? customGetter
          : getFirstPage;

        main({
          id: listId,
          per_page: pagination.pageSize,
          filter: transformFilterToServer(filterModel, disableQueryTransform),
          sort: transformSortToServer(sortModel),
          query: query,
        })
          .then((response) => {
            const data = response.data;
            let newRows = handleContacts(data.results, resultsHandler);
            setRows(newRows);
            delete data.results;
            setServerInfo({
              ...data,
              url: data.current_page.slice(0, -1), // For pagination
            });

            setRowCount(data.number_of_results);

            apiRef?.current?.setPage(0);
          })
          .finally(() => {
            setLoading(false);
          });

        additionalMainRequest?.();
      } else {
        requestPage(serverInfo.url, pagination.page + 1) //Pagination on table starts from 0
          .then((response) => {
            const data = response.data;
            setRows(handleContacts(data.results, resultsHandler));
          })
          .finally(() => setLoading(false));
      }
    },
    [
      additionalMainRequest,
      apiRef,
      disableQueryTransform,
      getFirstPage,
      listId,
      loading,
      resultsHandler,
      searchFunction,
      serverInfo.url,
    ]
  );

  const [tempPageSize, setTempPageSize] = useState(defaultPageSize);
  const onPaginationChange = useCallback(
    ({ page, pageSize }) => {
      setTempPageSize(pageSize);
      handleLoading(pageSize !== tempPageSize);
    },
    [handleLoading, tempPageSize]
  );

  const onFilterChange = useCallback(
    (model, details) => {
      handleLoading(true);
    },
    [handleLoading]
  );

  const onSortChange = useCallback(
    (sort) => {
      handleLoading(true);
    },
    [handleLoading]
  );

  // Column handling
  useEffect(() => {
    if (reloadColumns) {
      const idColumn = numberColumn(idField, "ID", 100);
      if (columnsHandler) {
        setColumns(columnsHandler());
      } else {
        let c = startingColumns?.length > 0 ? [...startingColumns] : [idColumn];
        const datafields = [...useConfigStore.getState().datafields];

        const order =
          useProfileStore.getState().profile?.saved_order?.[pageOrderName]
            ?.order_columns || [];

        datafields?.sort((a, b) => {
          let indexA = order?.indexOf(a?.id.toString());
          let indexB = order?.indexOf(b?.id.toString());

          // If the id is not in the order array, it should be placed at the end
          if (indexA === -1) indexA = Infinity;
          if (indexB === -1) indexB = Infinity;

          return indexA - indexB;
        });

        datafields?.forEach((datafield) => {
          c.push({
            field: datafield.id.toString(),
            identifier_placeholder: datafield.identifier_placeholder,
            headerName: datafield.name,
            width: getDatafieldColumnWidth(datafield),
            created: !!datafield?.created_by,
            visible: true,
            cellClassName: "hover-source",
            editable: cellEditable,
            type:
              datafield?.possible_values?.length > 0 ? "singleSelect" : "text",
            valueOptions: datafield?.possible_values,
            headerClassName: datafield?.created_by ? "custom_datafield" : "",
            renderHeader: renderHeader?.[datafield.id]
              ? (header) => renderHeader[datafield.id]({ ...header, datafield })
              : headerRendererCustomField && datafield?.created_by
              ? (header) => headerRendererCustomField(datafield)
              : undefined,
            renderCell: ({ row, value }) => (
              <Cell
                row={row}
                value={value}
                apiRef={apiRef}
                datafield={datafield}
                listId={listId}
                disableEdit={disableEdit}
              />
            ),
          });
        });

        c.push(...additionalColumns);

        setColumns(c);
        setReloadColumns?.(false);
      }
    }
  }, [reloadColumns]);

  // Initialization
  useEffect(() => {
    if (columns?.length) {
      handleLoading(true);
    }
  }, [columns?.length]);

  return {
    loading,
    columns,
    rows,
    rowCount,
    handleLoading,
    onPaginationChange,
    onFilterChange,
    onSortChange,
    serverInfo,
  };
};
